// Sidebar.js
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import SidebarElement from "./SidebarElement";
import { Scrollbars } from "react-custom-scrollbars";
import { sidebarRoutes } from "./sidebarRoutes";
import { useSelector } from "react-redux";
import useWindowSize from "../../hooks/useWindowSize";
const Sidebar = (props) => {
  const { width } = useWindowSize();
  const location = useLocation();
  const { isOpen, collapsedSidebar } = props;
  const mcontent = useRef(null);
  const { collapseChilds } = useSelector((state) => state.app);

  const renderView = ({ style, ...props }) => {
    const customStyle = {
      marginRight: "auto",
      marginRight: -17,
      // [rtl ? "marginLeft" : "marginRight"]: "-17px",
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  const renderThumbVertical = ({ style, ...props }) => {
    // const { ChangeLayoutMode } = this.props;
    const thumbStyle = {
      borderRadius: 6,
      background: "red",
      // backgroundColor: ChangeLayoutMode ? "#ffffff16" : "#F1F2F6",
      left: "2px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: "absolute",
      width: "6px",
      transition: "opacity 200ms ease 0s",
      opacity: 0,
      right: 2,
      // [rtl ? "left" : "right"]: "2px",
      bottom: "2px",
      top: "2px",
      borderRadius: "3px",
    };
    return <div style={thumbStyle} />;
  };

  const renderThumbHorizontal = ({ style, ...props }) => {
    // const { ChangeLayoutMode } = this.props;
    const thumbStyle = {
      borderRadius: 6,
      // backgroundColor: ChangeLayoutMode ? "#ffffff16" : "#F1F2F6",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const getPathName = (pathname) => {
    const pathMappings = {
      "/create-user": "/equipe",
      "/add-product": "/stock",
      "/add-commande": "/commande-client",
      "/add-client-commande": "/clientExtra-commandes",
      "/commande-client": "/commande-client",
    };
    if (pathMappings[pathname]) {
      return pathMappings[pathname];
    }

    const editProductPattern = /^\/edit-product\/\d+$/;
    const arrivageProductPattern = /^\/arrivage-product\/\d+$/;
    const invoicePattern = /^\/invoice\/[^/]+$/;
    const editUserPattern = /^\/edit-user\/\d+$/;
    const addClientCommandePattern = /^\/commande-client\/?.*$/;
    const colisPattern = /^\/colis\/?.*$/;
    if (
      editProductPattern.test(pathname) ||
      arrivageProductPattern.test(pathname)
    ) {
      return "/stock";
    }
    if (invoicePattern.test(pathname)) {
      return "/bon-de-sortie";
    }
    if (editUserPattern.test(pathname)) {
      return "/equipe";
    }
    if (colisPattern.test(pathname)) {
      return "/colis";
    }
    if (addClientCommandePattern.test(pathname)) {
      return "/commande-client";
    }
    return pathname;
  };

  return (
    <div
      className={`orty-sbar ${collapsedSidebar ? "mini-sider" : "sider"} ${
        !isOpen && width <= 850 ? "mobile-sider-close" : ""
      }`}
    >
      <Scrollbars
        className="custom-scrollbar"
        autoHide
        autoHideTimeout={500}
        autoHideDuration={200}
        renderThumbHorizontal={renderThumbHorizontal}
        renderThumbVertical={renderThumbVertical}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        <div className={`sb-mob-fullscreen overlay-sidebar-notvisible`} />
        <div
          style={{ paddingBottom: 60 }}
          ref={mcontent}
          className={`sidebar-parent${
            collapsedSidebar ? " sidebar-parent-collapsed" : ""
          }${!isOpen && width <= 850 ? " sidebar-closed-mobile" : ""}`}
        >
          <div
            style={{ position: "relative" }}
            className={`sidebar-content sidebar-pad`}
          >
            <div className="sidebar-dblock" />

            <div
              style={{ position: "relative" }}
              className={`sidebar-routes ${
                collapsedSidebar
                  ? "sidebar-routes-collapsed"
                  : "sidebar-routes-notcollapsed"
              }`}
            >
              <div className="sidebar-elems">
                <ul className="sidebar-elems-pads">
                  {/** sidebarRoute and filter the by keys. */}
                  {sidebarRoutes?.map((item, index) => {
                    const locationPathName = getPathName(location?.pathname);
                    return (
                      <SidebarElement
                        collapsedSidebar={collapsedSidebar}
                        key={`sb_route_${index}`}
                        hasChild={item?.hasChild}
                        items={item?.items}
                        title={item?.title}
                        path={item?.path}
                        activeItem={locationPathName}
                        isActive={item?.path === locationPathName}
                        collapseChilds={collapseChilds}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default Sidebar;
