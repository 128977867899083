import React, { useState, useEffect } from "react";
import { OrtyArrowLeft, OrtyArrowRight } from "../../utils/icons";
import StatisticScreenImg from "../../assets/services/Statistics.png";
import StockScreenImg from "../../assets/services/Stock.png";
import UserScreenImg from "../../assets/services/Equipe.png";

const slides = [
  {
    img: StatisticScreenImg,
    text: "Comment Suivre Vos Statistiques et gain?",
    desc: "Obtenez des insights détaillés sur vos envois et performances logistiques. Simplifiez le suivi de vos colis pour une gestion plus efficace et réactive.",
  },
  {
    img: StockScreenImg,
    text: "Comment Gérer et Ajouter des Produits et Arrivages?",
    desc: "Facilitez l'ajout de nouveaux produits et la gestion des arrivages avec notre système intuitif. Assurez une gestion fluide et précise de votre inventaire pour une meilleure efficacité.",
  },
  {
    img: UserScreenImg,
    text: "Comment Gérer les Utilisateurs et leurs Permissions?",
    desc: "Configurez et attribuez les permissions nécessaires à chaque utilisateur facilement. Assurez un contrôle précis et sécurisé des accès pour une gestion efficace de votre équipe.",
  },
];

const calculateTransformValues = (width) => {
  return [
    "0px",
    `-${width / 2 + 104.3}px`,
    `-${width + 208}px`
  ];
};

// const transformXValues = [
//   "0px", // Slide 1 position
//   "-900.632px", // Slide 2 position
//   "-1801.26px", // Slide 3 position
// ];

const SectionServices = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [zIndices, setZIndices] = useState([3, 2, 1]);
  const [transformXValues, setTransformXValues] = useState(calculateTransformValues(window.innerWidth));

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  //   }, 4000);

  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   const newZIndices = [1, 2, 3];
  //   newZIndices[activeIndex] = 4;
  //   setZIndices(newZIndices);
  // }, [activeIndex]);


  useEffect(() => {
    const handleResize = () => {
      setTransformXValues(calculateTransformValues(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % transformXValues.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [transformXValues]);

  useEffect(() => {
    const newZIndices = [1, 2, 3];
    newZIndices[activeIndex] = 4;
    setZIndices(newZIndices);
  }, [activeIndex]);


  return (
    <section id="strategy-section" className="content-panel-usp1">
      <div className="content home vp w-container">
        <div className="panel-text centered vp">
          <h2 className="panel-heading center">
            Gérez Votre Stock
            <br />
            Efficacement
          </h2>
          <p className="panel-paragraph center">
            Rationalisez la gestion de votre inventaire avec notre tableau de
            bord intuitif. Obtenez une vue d'ensemble claire des niveaux de
            stock, des ventes et des besoins de réapprovisionnement. Améliorez
            la satisfaction client et augmentez votre rentabilité grâce à une
            gestion optimisée des stocks.
          </p>
        </div>
        <div className="panel-visual-dashboard">
          <div className="vp_visualframe">
            <div
              data-delay="4000"
              data-animation="fade"
              className="vp1-slider w-slider"
              data-autoplay="true"
              data-easing="ease"
              data-hide-arrows="false"
              data-disable-swipe="false"
              data-autoplay-limit="0"
              data-nav-spacing="3"
              data-duration="250"
              data-infinite="true"
              role="region"
              aria-label="carousel"
            >
              <div className="mask-2 w-slider-mask" id="w-slider-mask-0">
                {slides.map((slide, index) => {
                  return (
                    <div
                      key={index}
                      className={`slide-${index + 1} w-slide`}
                      aria-label={`${index + 1} of ${slides.length}`}
                      role="group"
                      style={{
                        transform: `translateX(${transformXValues[activeIndex]})`,
                        opacity: 1,
                        zIndex: zIndices[index],
                        transition: "transform 250ms ease 0s",
                      }}
                    >
                      <img
                        width="894"
                        sizes="(max-width: 479px) 92vw, (max-width: 767px) 72vw, (max-width: 991px) 71vw, (max-width: 1439px) 67vw, 894px"
                        src={slide.img}
                        loading="lazy"
                        className="screen"
                      />
                    </div>
                  );
                })}
              </div>
              <div
                className="left-arrow w-slider-arrow-left"
                role="button"
                tabIndex="0"
                aria-controls="w-slider-mask-0"
                aria-label="previous slide"
                onClick={() =>
                  setActiveIndex(
                    (activeIndex - 1 + slides.length) % slides.length
                  )
                }
              >
                <OrtyArrowLeft />
              </div>
              <div
                className="right-arrow w-slider-arrow-right"
                role="button"
                tabIndex="0"
                aria-controls="w-slider-mask-0"
                aria-label="next slide"
                onClick={() =>
                  setActiveIndex((activeIndex + 1) % slides.length)
                }
              >
                <OrtyArrowRight />
              </div>
              <div className="slide-nav-2 w-slider-nav w-round">
                {slides.map((_, index) => (
                  <div
                    key={index}
                    className={`w-slider-dot ${
                      activeIndex === index ? "w-active" : ""
                    }`}
                    data-wf-ignore=""
                    aria-label={`Show slide ${index + 1} of ${slides.length}`}
                    aria-pressed={activeIndex === index}
                    role="button"
                    tabIndex="0"
                    onClick={() => setActiveIndex(index)}
                    style={{ marginLeft: "3px", marginRight: "3px" }}
                  ></div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-layout-vflex vp_captionframe">
            <div className="w-layout-vflex">
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`vp1_t${index + 1}`}
                  style={{ display: activeIndex === index ? "block" : "none" }}
                >
                  <h3 className="heading-2 caption">{slide.text}</h3>
                  <p className="paragraph-2">{slide.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionServices;
