import {
  ADD_CATEGORY_SPINNER_DIS,
  ADD_CATEGORY_SPINNER_EN,
  ADD_CATEGORY_SUCCESS,
  GET_CATEGORIES_SPINNER_DIS,
  GET_CATEGORIES_SPINNER_EN,
  GET_CATEGORIES_SUCCESS,
  ADD_PRODUCT_SPINNER_DIS,
  ADD_PRODUCT_SPINNER_EN,
  ADD_PRODUCT_SUCCESS,
  ADD_STOCK_SUCCESS,
  DELETE_PRODUCT_SPINNER_DIS,
  DELETE_PRODUCT_SPINNER_EN,
  DELETE_PRODUCT_SUCCESS,
  SELECT_PRODUCT_INDEX_TO_DELETE,
  VALID_REF_INPUT_SPINNER_DIS,
  VALID_REF_INPUT_SPINNER_EN,
  VALID_REF_INPUT_SUCCESS,
  GET_PRODUCTS_SPINNER_EN,
  GET_PRODUCTS_SPINNER_DIS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_DETAILS_LOADER_EN,
  GET_PRODUCT_DETAILS_LOADER_DIS,
  GET_PRODUCT_DETAILS,
  UPDATE_PRODUCT_SPINNER_EN,
  UPDATE_PRODUCT_SPINNER_DIS,
  UPDATE_PRODUCT_SUCCESS,
  RESET_VALID_REF,
  GET_PRODUCT_SUGGESTION_SPINNER_EN,
  GET_PRODUCT_SUGGESTION_SPINNER_DIS,
  GET_PRODUCT_SUGGESTION_SUCCESS,
  ADD_ARRIVAGE_SPINNER_EN,
  ADD_ARRIVAGE_SPINNER_DIS,
  ADD_ARRIVAGE_SUCCESS,
  GET_ARRIVAGE_DETAILS_SPINNER_EN,
  GET_ARRIVAGE_DETAILS_SPINNER_DIS,
  GET_ARRIVAGE_DETAILS_SUCCESS,
  RESTORE_PRODUCT_SUCCESS,
  ADD_SWITCH_ID_TO_LOADER,
  REMOVE_SWITCH_ID_FROM_LOADER,
  RESTORE_PRODUCT_SPINNER_EN,
  RESTORE_PRODUCT_SPINNER_DIS,
  DELETE_CATEGORY_SPINNER_EN,
  DELETE_CATEGORY_SPINNER_DIS,
  DELETE_CATEGORY_SUCCESS,
  UPDATE_ARRIVAGE_SPINNER_EN,
  UPDATE_ARRIVAGE_SPINNER_DIS,
  UPDATE_ARRIVAGE_SUCCESS,
  UPDATE_ARRIVAGE_SUCCESS_COMBINATION_GLOBAL,
} from "../types";

const initialState = {
  productDetails: null,
  productDetailsLoader: false,
  products: [],
  get_products_spinner: false,
  add_product_spinner: false,
  del_product_spinner: false,
  select_idx_to_delete: null,
  categories: [],
  add_cat_spinner: false,
  get_cat_spinner: false,
  input_valid: null,
  isvalid_input_loader: false,
  update_loader: false,
  suggestion_loader: false,
  suggestions: [],
  add_arrivage_loader: false,
  arrivageHistorique: [],
  arrivageHLoader: false,
  switch_spinner: false,
  switch_prodIds: [],
  delete_category_spinner: false,
  arrivageLoaderUpdate: false,
};

export default function stockReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ARRIVAGE_SUCCESS_COMBINATION_GLOBAL:
      return {
        ...state,
        arrivageHistorique: state.arrivageHistorique.map((arrivage) =>
          arrivage.id === action.payload.arrivageId
            ? { ...arrivage, globalQuantity: action.payload.arrivageQte }
            : arrivage
        ),
      };
    case UPDATE_ARRIVAGE_SUCCESS:
      const { updatedProductId, prod } = action.payload;
      return {
        ...state,
        products: state.products.map((product) =>
          product?.id === updatedProductId
            ? {
                ...product,
                globalQuantity: prod?.globalQuantity,
                totalQuantity: prod?.totalQuantity,
              }
            : product
        ),
      };
    case UPDATE_ARRIVAGE_SPINNER_EN:
      return {
        ...state,
        arrivageLoaderUpdate: true,
      };
    case UPDATE_ARRIVAGE_SPINNER_DIS:
      return {
        ...state,
        arrivageLoaderUpdate: false,
      };
    case DELETE_CATEGORY_SPINNER_EN:
      return {
        ...state,
        delete_category_spinner: true,
      };
    case DELETE_CATEGORY_SPINNER_DIS:
      return {
        ...state,
        delete_category_spinner: false,
      };
    case DELETE_CATEGORY_SUCCESS: {
      const { catId } = action.payload;

      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.categoryId !== catId
        ),
      };
    }
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
      };
    case DELETE_PRODUCT_SUCCESS:
      const updateProductAfterDelete = state.products.map((product) =>
        product.id === action.id ? action.payload : product
      );
      return {
        ...state,
        products: updateProductAfterDelete,
      };
    case RESTORE_PRODUCT_SUCCESS:
      const updateProductAfterRestore = state.products.map((product) =>
        product.id === action.id ? action.payload : product
      );
      return {
        ...state,
        products: updateProductAfterRestore,
      };

    case ADD_SWITCH_ID_TO_LOADER:
      if (!state.switch_prodIds.includes(action.id)) {
        return {
          ...state,
          switch_prodIds: [...state.switch_prodIds, action.id],
        };
      }
      return state;

    case REMOVE_SWITCH_ID_FROM_LOADER:
      return {
        ...state,
        switch_prodIds: state.switch_prodIds.filter((id) => id !== action.id),
      };

    case RESTORE_PRODUCT_SPINNER_EN:
      return {
        ...state,
        switch_spinner: true,
      };
    case RESTORE_PRODUCT_SPINNER_DIS:
      return {
        ...state,
        switch_spinner: false,
      };
    case DELETE_PRODUCT_SPINNER_EN:
      return {
        ...state,
        switch_spinner: true,
      };
    case DELETE_PRODUCT_SPINNER_DIS:
      return {
        ...state,
        switch_spinner: false,
      };

    case GET_ARRIVAGE_DETAILS_SPINNER_EN:
      return {
        ...state,
        arrivageHLoader: true,
      };
    case GET_ARRIVAGE_DETAILS_SPINNER_DIS:
      return {
        ...state,
        arrivageHLoader: false,
      };
    case GET_ARRIVAGE_DETAILS_SUCCESS:
      return {
        ...state,
        arrivageHistorique: action.payload,
      };
    case GET_PRODUCT_SUGGESTION_SPINNER_EN:
      return {
        ...state,
        suggestion_loader: true,
      };
    case GET_PRODUCT_SUGGESTION_SPINNER_DIS:
      return {
        ...state,
        suggestion_loader: false,
      };
    case GET_PRODUCT_SUGGESTION_SUCCESS:
      return {
        ...state,
        suggestions: action.payload,
      };
    case RESET_VALID_REF:
      return {
        ...state,
        input_valid: null,
        isvalid_input_loader: false,
      };

    case ADD_ARRIVAGE_SPINNER_EN:
      return {
        ...state,
        add_arrivage_loader: true,
      };
    case ADD_ARRIVAGE_SPINNER_DIS:
      return {
        ...state,
        add_arrivage_loader: false,
      };
    case ADD_ARRIVAGE_SUCCESS:
      const { pId, prodct } = action.payload;
      const updatedAfterArrivage = state.products.map((p) =>
        p.id === pId ? prodct : p
      );
      return {
        ...state,
        products: updatedAfterArrivage,
      };

    case UPDATE_PRODUCT_SPINNER_EN:
      return {
        ...state,
        update_loader: true,
      };
    case UPDATE_PRODUCT_SPINNER_DIS:
      return {
        ...state,
        update_loader: false,
      };
    case UPDATE_PRODUCT_SUCCESS:
      const { product, productId } = action.payload;
      const updateProduct = state.products.map((p) =>
        p.id === productId ? product : p
      );
      return {
        ...state,
        products: updateProduct,
      };
    case GET_PRODUCT_DETAILS_LOADER_EN:
      return {
        ...state,
        productDetailsLoader: true,
      };
    case GET_PRODUCT_DETAILS_LOADER_DIS:
      return {
        ...state,
        productDetailsLoader: false,
      };
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.payload,
      };
    case GET_PRODUCTS_SPINNER_EN:
      return {
        ...state,
        get_products_spinner: true,
      };
    case GET_PRODUCTS_SPINNER_DIS:
      return {
        ...state,
        get_products_spinner: false,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      };
    case VALID_REF_INPUT_SPINNER_EN:
      return {
        ...state,
        isvalid_input_loader: true,
      };
    case VALID_REF_INPUT_SPINNER_DIS:
      return {
        ...state,
        isvalid_input_loader: false,
      };
    case VALID_REF_INPUT_SUCCESS:
      return {
        ...state,
        input_valid: action.payload,
      };
    case SELECT_PRODUCT_INDEX_TO_DELETE:
      return {
        ...state,
        select_idx_to_delete: action?.payload?.ref,
      };
    case ADD_PRODUCT_SPINNER_EN:
      return {
        ...state,
        add_product_spinner: true,
      };
    case ADD_PRODUCT_SPINNER_DIS:
      return {
        ...state,
        add_product_spinner: false,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [action.payload, ...state?.products],
      };
    case ADD_CATEGORY_SPINNER_EN:
      return {
        ...state,
        add_cat_spinner: true,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [
          ...state.categories,
          { ...action.payload, productCount: 0 },
        ],
      };
    case ADD_CATEGORY_SPINNER_DIS:
      return {
        ...state,
        get_cat_spinner: false,
      };

    case GET_CATEGORIES_SPINNER_EN:
      return {
        ...state,
        get_cat_spinner: true,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_CATEGORIES_SPINNER_DIS:
      return {
        ...state,
        get_cat_spinner: false,
      };
    case ADD_STOCK_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    default:
      return state;
  }
}
