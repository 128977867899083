export const COLLAPSE_SIDEBAR_ELEMENTS = "COLLAPSE_SIDEBAR_ELEMENTS";
export const ADD_FOURNISSEUR_MODAL_OPEN = "ADD_FOURNISSEUR_MODAL_OPEN";
export const ADD_FOURNISSEUR_MODAL_CLOSE = "ADD_FOURNISSEUR_MODAL_CLOSE";
export const ADD_SPONSORING_MODAL_OPEN = "ADD_SPONSORING_MODAL_OPEN";
export const ADD_SPONSORING_MODAL_CLOSE = "ADD_SPONSORING_MODAL_CLOSE";
export const ADD_STOCK_CAT_MODAL_OPEN = "ADD_STOCK_CAT_MODAL_OPEN";
export const ADD_STOCK_CAT_MODAL_CLOSE = "ADD_STOCK_CAT_MODAL_CLOSE";
export const ADD_LIVRED_COMPANY_OPEN_MODAL = "ADD_LIVRED_COMPANY_OPEN_MODAL";
export const ADD_LIVRED_COMPANY_CLOSE_MODAL = "ADD_LIVRED_COMPANY_CLOSE_MODAL";
export const ADD_BOUTIQUE_MODAL_OPEN = "ADD_BOUTIQUE_MODAL_OPEN";
export const ADD_BOUTIQUE_MODAL_CLOSE = "ADD_BOUTIQUE_MODAL_CLOSE";

export const GET_STOCK_SPINNER_EN = "GET_STOCK_SPINNER_EN";
export const GET_STOCK_SPINNER_DIS = "GET_STOCK_SPINNER_DIS";
export const GET_STOCK_SUCCESS = "GET_STOCK_SUCCESS";

export const ADD_STOCK_SUCCESS = "ADD_STOCK_SUCCESS";
export const ADD_STOCK_SPINNER_EN = "ADD_STOCK_SPINNER_EN";
export const ADD_STOCK_SPINNER_DIS = "ADD_STOCK_SPINNER_DIS";

export const ADD_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE =
  "ADD_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE";
export const UPDATE_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE =
  "UPDATE_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE";
export const DELETE_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE =
  "DELETE_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE";
export const RESET_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE =
  "RESET_SELECTED_PRODUCT_TO_ADD_FOR_COMMANDE";

export const ADD_COMMANDE_SPINNER_EN = "ADD_COMMANDE_SPINNER_EN";
export const ADD_COMMANDE_SPINNER_DIS = "ADD_COMMANDE_SPINNER_DIS";
export const ADD_COMMANDE_SUCCESS = "ADD_COMMANDE_SUCCESS";

export const GET_COMMANDE_SPINNER_EN = "GET_COMMANDE_SPINNER_EN";
export const GET_COMMANDE_SPINNER_DIS = "GET_COMMANDE_SPINNER_DIS";
export const GET_COMMANDE_SUCCESS = "GET_COMMANDE_SUCCESS";

export const GET_COLIS_SPINNER_EN = "GET_COLIS_SPINNER_EN";
export const GET_COLIS_SPINNER_DIS = "GET_COLIS_SPINNER_DIS";
export const GET_COLIS_SUCCESS = "GET_COLIS_SUCCESS";

// delete commande:
export const SELECT_COMMANDE_INDEX_TO_DELETE =
  "SELECT_COMMANDE_INDEX_TO_DELETE";
export const DELETE_COMMANDE_SPINNER_EN = "DELETE_COMMANDE_SPINNER_EN";
export const DELETE_COMMADNE_SPINNER_DIS = "DELETE_COMMADNE_SPINNER_DIS";
export const DELETE_COMMANDE_SUCCESS = "DELETE_COMMANDE_SUCCESS";

export const GET_BOUTIQUES_SUCCESS = "GET_BOUTIQUES_SUCCESS";
export const GET_BOUTIQUES_SPINNER_EN = "GET_BOUTIQUES_SPINNER_EN";
export const GET_BOUTIQUES_SPINNER_DIS = "GET_BOUTIQUES_SPINNER_DIS";

export const ADD_BOUTIQUE_SUCCESS = "ADD_BOUTIQUE_SUCCESS";
export const ADD_BOUTIQUE_SPINNER_EN = "ADD_BOUTIQUE_SPINNER_EN";
export const ADD_BOUTIQUE_SPINNER_DIS = "ADD_BOUTIQUE_SPINNER_DIS";

export const GET_FOURNISSEUR_SUCCESS = "GET_FOURNISSEUR_SUCCESS";
export const GET_FOURNISSEUR_SPINNER_EN = "GET_FOURNISSEUR_SPINNER_EN";
export const GET_FOURNISSEUR_SPINNER_DIS = "GET_FOURNISSEUR_SPINNER_DIS";

export const ADD_FOURNISSEUR_SUCCESS = "ADD_FOURNISSEUR_SUCCESS";
export const ADD_FOURNISSEUR_SPINNER_EN = "ADD_FOURNISSEUR_SPINNER_EN";
export const ADD_FOURNISSEUR_SPINNER_DIS = "ADD_FOURNISSEUR_SPINNER_DIS";

export const GET_CATEGORIES_SPINNER_EN = "GET_CATEGORIES_SPINNER_EN";
export const GET_CATEGORIES_SPINNER_DIS = "GET_CATEGORIES_SPINNER_DIS";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

export const ADD_CATEGORY_SPINNER_EN = "ADD_CATEGORY_SPINNER_EN";
export const ADD_CATEGORY_SPINNER_DIS = "ADD_CATEGORY_SPINNER_DIS";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";

export const ADD_DELIVERY_COMPANY_SUCCESS = "ADD_DELIVERY_COMPANY_SUCCESS";
export const ADD_DELIVERY_COMPANY_SPINNER_EN =
  "ADD_DELIVERY_COMPANY_SPINNER_EN";
export const ADD_DELIVERY_COMPANY_SPINNER_DIS =
  "ADD_DELIVERY_COMPANY_SPINNER_DIS";

export const ADD_PRODUCT_SPINNER_EN = "ADD_PRODUCT_SPINNER_EN";
export const ADD_PRODUCT_SPINNER_DIS = "ADD_PRODUCT_SPINNER_DIS";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";

export const SELECT_PRODUCT_INDEX_TO_DELETE = "SELECT_PRODUCT_INDEX_TO_DELETE";
export const DELETE_PRODUCT_SPINNER_EN = "DELETE_PRODUCT_SPINNER_EN";
export const DELETE_PRODUCT_SPINNER_DIS = "DELETE_PRODUCT_SPINNER_DIS";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";

export const RESTORE_PRODUCT_SPINNER_EN = "RESTORE_PRODUCT_SPINNER_EN";
export const RESTORE_PRODUCT_SPINNER_DIS = "RESTORE_PRODUCT_SPINNER_DIS";
export const RESTORE_PRODUCT_SUCCESS = "RESTORE_PRODUCT_SUCCESS";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SPINNER_EN = "LOGIN_SPINNER_EN";
export const LOGIN_SPINNER_DIS = "LOGIN_SPINNER_DIS";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_SPINNER_EN = "REGISTER_SPINNER_EN";
export const REGISTER_SPINNER_DIS = "REGISTER_SPINNER_DIS";

export const VALID_REF_INPUT_SPINNER_EN = "VALID_REF_INPUT_SPINNER_EN";
export const VALID_REF_INPUT_SPINNER_DIS = "VALID_REF_INPUT_SPINNER_DIS";
export const VALID_REF_INPUT_SUCCESS = "VALID_REF_INPUT_SUCCESS";

export const GET_PRODUCTS_SPINNER_EN = "GET_PRODUCTS_SPINNER_EN";
export const GET_PRODUCTS_SPINNER_DIS = "GET_PRODUCTS_SPINNER_DIS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

export const GET_DELIVERY_COMPANY_SPINNER_EN =
  "GET_DELIVERY_COMPANY_SPINNER_EN";
export const GET_DELIVERY_COMPANY_SPINNER_DIS =
  "GET_DELIVERY_COMPANY_SPINNER_DIS";
export const GET_DELIVERY_COMPANY_SUCCESS = "GET_DELIVERY_COMPANY_SUCCESS";

export const ADD_SPONSORING_SPINNER_EN = "ADD_SPONSORING_SPINNER_EN";
export const ADD_SPONSORING_SPINNER_DIS = "ADD_SPONSORING_SPINNER_DIS";
export const ADD_SPONSORING_SUCCESS = "ADD_SPONSORING_SUCCESS";

export const GET_SPONSORING_SPINNER_EN = "GET_SPONSORING_SPINNER_EN";
export const GET_SPONSORING_SPINNER_DIS = "GET_SPONSORING_SPINNER_DIS";
export const GET_SPONSORING_SUCCESS = "GET_SPONSORING_SUCCESS";

export const RESET_COMMANDE_TEMP = "RESET_COMMANDE_TEMP";

export const CHANGE_COMMANDE_STATUS_SPINNER_EN =
  "CHANGE_COMMANDE_STATUS_SPINNER_EN";
export const CHANGE_COMMANDE_STATUS_SPINNER_DIS =
  "CHANGE_COMMANDE_STATUS_SPINNER_DIS";
export const CHANGE_COMMANDE_STATUS_SUCCESS = "CHANGE_COMMANDE_STATUS_SUCCESS";
export const RESET_CHANGE_COMMANDE = "RESET_CHANGE_COMMANDE";

export const UPDATE_PRODUCT_ON_TMP_COMMANDE = "UPDATE_PRODUCT_ON_TMP_COMMANDE";
export const DELETE_IDS_FROM_COMMANDE = "DELETE_IDS_FROM_COMMANDE";

export const CHANGE_COLIS_STATUS_SUCCESS = "CHANGE_COLIS_STATUS_SUCCESS";
export const CHANGE_COLIS_STATUS_SPINNER_EN = "CHANGE_COLIS_STATUS_SPINNER_EN";
export const CHANGE_COLIS_STATUS_SPINNER_DIS =
  "CHANGE_COLIS_STATUS_SPINNER_DIS";

export const ADD_PAYMENT_OPEN_MODAL = "ADD_PAYMENT_OPEN_MODAL";
export const ADD_PAYMENT_CLOSE_MODAL = "ADD_PAYMENT_CLOSE_MODAL";

export const ADD_PAIEMENT_SUCCESS = "ADD_PAIEMENT_SUCCESS";
export const ADD_PAIEMENT_LOADER_EN = "ADD_PAIEMENT_LOADER_EN";
export const ADD_PAIEMENT_LOADER_DIS = "ADD_PAIEMENT_LOADER_DIS";

export const GET_PAIEMENTS_SUCCESS = "GET_PAIEMENTS_SUCCESS";
export const GET_PAIEMENTS_LOADER_EN = "GET_PAIEMENTS_LOADER_EN";
export const GET_PAIEMENTS_LOADER_DIS = "GET_PAIEMENTS_LOADER_DIS";

export const GET_CHARGES_SUCCESS = "GET_CHARGES_SUCCESS";
export const GET_CHARGES_LOADER_EN = "GET_CHARGES_LOADER_EN";
export const GET_CHARGES_LOADER_DIS = "GET_CHARGES_LOADER_DIS";

export const ADD_CHARGE_SUCCESS = "ADD_CHARGE_SUCCESS";
export const ADD_CHARGE_LOADER_EN = "ADD_CHARGE_LOADER_EN";
export const ADD_CHARGE_LOADER_DIS = "ADD_CHARGE_LOADER_DIS";

export const CHARGE_MODAL_OPEN = "CHARGE_MODAL_OPEN";
export const CHARGE_MODAL_CLOSE = "CHARGE_MODAL_CLOSE";

export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_PRODUCT_DETAILS_LOADER_EN = "GET_PRODUCT_DETAILS_LOADER_EN";
export const GET_PRODUCT_DETAILS_LOADER_DIS = "GET_PRODUCT_DETAILS_LOADER_DIS";

export const UPDATE_MODAL_OPEN = "UPDATE_MODAL_OPEN";
export const UPDATE_MODAL_CLOSE = "UPDATE_MODAL_CLOSE";

export const UPDATE_CHARGE_SUCCESS = "UPDATE_CHARGE_SUCCESS";
export const UPDATE_LOADER_EN = "UPDATE_LOADER_EN";
export const UPDATE_LOADER_DIS = "UPDATE_LOADER_DIS";

export const UPDATE_PAIEMENT_SUCCESS = "UPDATE_PAIEMENT_SUCCESS";
export const UPDATE_DELIEVERED_COMPANY_SUCCESS =
  "UPDATE_DELIEVERED_COMPANY_SUCCESS";
export const UPDATE_FOURNISSEUR_SUCCESS = "UPDATE_FOURNISSEUR_SUCCESS";
export const UPDATE_SPONSORING_SUCCESS = "UPDATE_SPONSORING_SUCCESS";

export const ADD_ATTRIBUTE_OPTION_SUCCESS = "ADD_ATTRIBUTE_OPTION_SUCCESS";
export const ADD_ATTRIBUTE_OPTION_SPINNER_EN =
  "ADD_ATTRIBUTE_OPTION_SPINNER_EN";
export const ADD_ATTRIBUTE_OPTION_SPINNER_DIS =
  "ADD_ATTRIBUTE_OPTION_SPINNER_DIS";

export const ADD_ATTRIBUTE_VALUE_SUCCESS = "ADD_ATTRIBUTE_VALUE_SUCCESS";
export const ADD_ATTRIBUTE_VALUE_SPINNER_EN = "ADD_ATTRIBUTE_VALUE_SPINNER_EN";
export const ADD_ATTRIBUTE_VALUE_SPINNER_DIS =
  "ADD_ATTRIBUTE_VALUE_SPINNER_DIS";

export const GET_ATTRIBUTES_SUCCESS = "GET_ATTRIBUTES_SUCCESS";
export const GET_ATTRIBUTES_SPINNER_EN = "GET_ATTRIBUTES_SPINNER_EN";
export const GET_ATTRIBUTES_SPINNER_DIS = "GET_ATTRIBUTES_SPINNER_DIS";

export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_SPINNER_EN = "UPDATE_PRODUCT_SPINNER_EN";
export const UPDATE_PRODUCT_SPINNER_DIS = "UPDATE_PRODUCT_SPINNER_DIS";

export const RESET_VALID_REF = "RESET_VALID_REF";

export const GET_PRODUCT_SUGGESTION_SUCCESS = "GET_PRODUCT_SUGGESTION_SUCCESS";
export const GET_PRODUCT_SUGGESTION_SPINNER_EN =
  "GET_PRODUCT_SUGGESTION_SPINNER_EN";
export const GET_PRODUCT_SUGGESTION_SPINNER_DIS =
  "GET_PRODUCT_SUGGESTION_SPINNER_DIS";

export const GET_COMMANDE_DETAILS_SUCCESS = "GET_COMMANDE_DETAILS_SUCCESS";
export const GET_COMMANDE_DETAILS_SPINNER_EN =
  "GET_COMMANDE_DETAILS_SPINNER_EN";
export const GET_COMMANDE_DETAILS_SPINNER_DIS =
  "GET_COMMANDE_DETAILS_SPINNER_DIS";

export const UPDATE_COMMANDE_SUCCESS = "UPDATE_COMMANDE_SUCCESS";
export const UPDATE_COMMANDE_SPINNER_EN = "UPDATE_COMMANDE_SPINNER_EN";
export const UPDATE_COMMANDE_SPINNER_DIS = "UPDATE_COMMANDE_SPINNER_DIS";

export const COMMANDE_STATS = "COMMANDE_STATS";
export const UPDATE_COMMANDE_STATS = "UPDATE_COMMANDE_STATS";
export const COMMANDE_STATS_SPINNER_EN = "COMMANDE_STATS_SPINNER_EN";
export const COMMANDE_STATS_SPINNER_DIS = "COMMANDE_STATS_SPINNER_DIS";

export const GET_EXTRA_CLIENT_SPINNER_EN = "GET_EXTRA_CLIENT_SPINNER_EN";
export const GET_EXTRA_CLIENT_SPINNER_DIS = "GET_EXTRA_CLIENT_SPINNER_DIS";
export const GET_EXTRA_CLIENT_SUCCESS = "GET_EXTRA_CLIENT_SUCCESS";

export const DELETE_EXTRA_CLIENT_SPINNER_EN = "DELETE_EXTRA_CLIENT_SPINNER_EN";
export const DELETE_EXTRA_CLIENT_SPINNER_DIS =
  "DELETE_EXTRA_CLIENT_SPINNER_DIS";
export const DELETE_EXTRA_CLIENT_SUCCESS = "DELETE_EXTRA_CLIENT_SUCCESS";

export const RESTORE_EXTRA_CLIENT_SPINNER_EN =
  "RESTORE_EXTRA_CLIENT_SPINNER_EN";
export const RESTORE_EXTRA_CLIENT_SPINNER_DIS =
  "RESTORE_EXTRA_CLIENT_SPINNER_DIS";
export const RESTORE_EXTRA_CLIENT_SUCCESS = "DELETE_EXTRA_CLIENT_SUCCESS";

export const GET_COMMANDE_CLIENT_SPINNER_EN = "GET_COMMANDE_CLIENT_SPINNER_EN";
export const GET_COMMANDE_CLIENT_SPINNER_DIS =
  "GET_COMMANDE_CLIENT_SPINNER_DIS";
export const GET_COMMANDE_CLIENT_SUCCESS = "GET_COMMANDE_CLIENT_SUCCESS";

export const ADD_EXTRA_CLIENT_SPINNER_EN = "ADD_EXTRA_CLIENT_SPINNER_EN";
export const ADD_EXTRA_CLIENT_SPINNER_DIS = "ADD_EXTRA_CLIENT_SPINNER_DIS";
export const ADD_EXTRA_CLIENT_SUCCESS = "ADD_EXTRA_CLIENT_SUCCESS";

export const MODAL_OPEN_ADD_CLIENT_EXTRA = "MODAL_OPEN_ADD_CLIENT_EXTRA";
export const MODAL_CLOSE_ADD_CLIENT_EXTRA = "MODAL_CLOSE_ADD_CLIENT_EXTRA";

export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";

export const EXTRA_CLIENT_COMMANDES_LIST_SUCCESS =
  "EXTRA_CLIENT_COMMANDES_LIST_SUCCESS";
export const EXTRA_CLIENT_COMMANDES_SPINNER_EN =
  "EXTRA_CLIENT_COMMANDES_SPINNER_EN";
export const EXTRA_CLIENT_COMMANDES_SPINNER_DIS =
  "EXTRA_CLIENT_COMMANDES_SPINNER_DIS";

export const ADD_EXTRA_CLIENT_COMMANDES = "ADD_EXTRA_CLIENT_COMMANDES";
export const ADD_EXTRA_CLIENT_COMMANDES_SPINNER_EN =
  "ADD_EXTRA_CLIENT_COMMANDES_SPINNER_EN";
export const ADD_EXTRA_CLIENT_COMMANDES_SPINNER_DIS =
  "ADD_EXTRA_CLIENT_COMMANDES_SPINNER_DIS";

export const GET_USERS_SPINNER_EN = "GET_USERS_SPINNER_EN";
export const GET_USERS_SPINNER_DIS = "GET_USERS_SPINNER_DIS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

export const ADD_USER_SPINNER_EN = "ADD_USER_SPINNER_EN";
export const ADD_USER_SPINNER_DIS = "ADD_USER_SPINNER_DIS";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_SPINNER_EN = "GET_USER_SPINNER_EN";
export const GET_USER_SPINNER_DIS = "GET_USER_SPINNER_DIS";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_SPINNER_EN = "UPDATE_USER_SPINNER_EN";
export const UPDATE_USER_SPINNER_DIS = "UPDATE_USER_SPINNER_DIS";

export const RESET_USER_DETAILS = "RESET_USER_DETAILS";

export const UPDATE_PERMISSION_ON_USER_DETAILS =
  "UPDATE_PERMISSION_ON_USER_DETAILS";

export const GET_COMMANDE_BY_REF_SPINNER_EN = "GET_COMMANDE_BY_REF_SPINNER_EN";
export const GET_COMMANDE_BY_REF_SPINNER_DIS =
  "GET_COMMANDE_BY_REF_SPINNER_DIS";
export const GET_COMMANDE_BY_REF_SUCCESS = "GET_COMMANDE_BY_REF_SUCCESS";
export const GET_COMMANDE_BY_REF_RESET = "GET_COMMANDE_BY_REF_RESET";

export const ADD_ARRIVAGE_SUCCESS = "ADD_ARRIVAGE_SUCCESS";
export const ADD_ARRIVAGE_SPINNER_EN = "ADD_ARRIVAGE_SPINNER_EN";
export const ADD_ARRIVAGE_SPINNER_DIS = "ADD_ARRIVAGE_SPINNER_DIS";

export const GET_ARRIVAGE_DETAILS_SPINNER_EN =
  "GET_ARRIVAGE_DETAILS_SPINNER_EN";
export const GET_ARRIVAGE_DETAILS_SPINNER_DIS =
  "GET_ARRIVAGE_DETAILS_SPINNER_DIS";
export const GET_ARRIVAGE_DETAILS_SUCCESS = "GET_ARRIVAGE_DETAILS_SUCCESS";

export const DELETE_FOURNISSEUR_SUCCESS = "DELETE_FOURNISSEUR_SUCCESS";
export const DELETE_FOURNISSEUR_SPINNER_EN = "DELETE_FOURNISSEUR_SPINNER_EN";
export const DELETE_FOURNISSEUR_SPINNER_DIS = "DELETE_FOURNISSEUR_SPINNER_DIS";

export const RESTORE_FOURNISSEUR_SUCCESS = "RESTORE_FOURNISSEUR_SUCCESS";
export const RESTORE_FOURNISSEUR_SPINNER_EN = "RESTORE_FOURNISSEUR_SPINNER_EN";
export const RESTORE_FOURNISSEUR_SPINNER_DIS =
  "RESTORE_FOURNISSEUR_SPINNER_DIS";

export const ADD_SWITCH_ID_TO_LOADER = "ADD_SWITCH_ID_TO_LOADER";
export const REMOVE_SWITCH_ID_FROM_LOADER = "REMOVE_SWITCH_ID_FROM_LOADER";

export const DELETE_DC_SUCCESS = "DELETE_DC_SUCCESS";
export const DELETE_DC_SPINNER_EN = "DELETE_DC_SPINNER_EN";
export const DELETE_DC_SPINNER_DIS = "DELETE_DC_SPINNER_DIS";

export const RESTORE_DC_SUCCESS = "RESTORE_DC_SUCCESS";
export const RESTORE_DC_SPINNER_EN = "RESTORE_DC_SPINNER_EN";
export const RESTORE_DC_SPINNER_DIS = "RESTORE_DC_SPINNER_DIS";

export const USER_DELETE_SPINNER_EN = "USER_DELETE_SPINNER_EN";
export const USER_DELETE_SPINNER_DIS = "USER_DELETE_SPINNER_DIS";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";

export const RESTORE_USER_SPINNER_EN = "RESTORE_USER_SPINNER_EN";
export const RESTORE_USER_SPINNER_DIS = "RESTORE_USER_SPINNER_DIS";
export const RESTORE_USER_SUCCESS = "RESTORE_USER_SUCCESS";

export const GET_INCOME_BEGIN = "GET_INCOME_BEGIN";
export const GET_INCOME_SUCCESS = "GET_INCOME_SUCCESS";
export const GET_INCOME_ERR = "GET_INCOME_ERR";

export const GET_STATS_SPINNER_EN = "GET_STATS_SPINNER_EN";
export const GET_STATS_SPINNER_DIS = "GET_STATS_SPINNER_DIS";
export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";

export const GET_PROFILE_DETAILS_SPINNER_EN = "GET_PROFILE_DETAILS_SPINNER_EN";
export const GET_PROFILE_DETAILS_SPINNER_DIS =
  "GET_PROFILE_DETAILS_SPINNER_DIS";
export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS";

export const EDIT_PROFILE_SPINNER_EN = "EDIT_PROFILE_SPINNER_EN";
export const EDIT_PROFILE_SPINNER_DIS = "EDIT_PROFILE_SPINNER_DIS";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";

export const EDIT_PASSWORD_SPINNER_EN = "EDIT_PASSWORD_SPINNER_EN";
export const EDIT_PASSWORD_SPINNER_DIS = "EDIT_PASSWORD_SPINNER_DIS";
export const EDIT_PASSWORD_SUCCESS = "EDIT_PASSWORD_SUCCESS";

export const DELETE_IDS_FROM_COLIS = "DELETE_IDS_FROM_COLIS";

export const GET_STATISTICS_GAINS_SPINNER_EN =
  "GET_STATISTICS_GAINS_SPINNER_EN";
export const GET_STATISTICS_GAINS_SPINNER_DIS =
  "GET_STATISTICS_GAINS_SPINNER_DIS";
export const GET_STATISTICS_GAINS_SUCCESS = "GET_STATISTICS_GAINS_SUCCESS";

export const EDIT_USER_PASSWORD_SPINNER_EN = "EDIT_USER_PASSWORD_SPINNER_EN";
export const EDIT_USER_PASSWORD_SPINNER_DIS = "EDIT_USER_PASSWORD_SPINNER_DIS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";

export const GET_STATISTICS_PROFIT_SPINNER_EN =
  "GET_STATISTICS_PROFIT_SPINNER_EN";
export const GET_STATISTICS_PROFIT_SPINNER_DIS =
  "GET_STATISTICS_PROFIT_SPINNER_DIS";
export const GET_STATISTICS_PROFIT_SUCCESS =
  "GET_STATISTICS_PROFIT_SPINNER_SUCCESS";

export const DELETE_CATEGORY_SPINNER_EN = "DELETE_CATEGORY_SPINNER_EN";
export const DELETE_CATEGORY_SPINNER_DIS = "DELETE_CATEGORY_SPINNER_DIS";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

export const RETURN_COLIS_STATE_INITIAL_SPINNER_EN = "RETURN_COLIS_STATE_INITIAL_SPINNER_EN";
export const RETURN_COLIS_STATE_INITIAL_SPINNER_DIS = "RETURN_COLIS_STATE_INITIAL_SPINNER_DIS";
export const RETURN_COLIS_STATE_INITIAL_SUCCESS = "RETURN_COLIS_STATE_INITIAL_SUCCESS";

export const UPDATE_ARRIVAGE_SPINNER_EN = "UPDATE_ARRIVAGE_SPINNER_EN";
export const UPDATE_ARRIVAGE_SPINNER_DIS = "UPDATE_ARRIVAGE_SPINNER_DIS";
export const UPDATE_ARRIVAGE_SUCCESS = "UPDATE_ARRIVAGE_SUCCESS";
export const UPDATE_ARRIVAGE_SUCCESS_COMBINATION_GLOBAL = "UPDATE_ARRIVAGE_SUCCESS_COMBINATION_GLOBAL";